import {useAppTranslation} from "services/i18n";
import {useAppDispatch} from "store";
import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {cancelLearningEventOrder, confirmLearningEventOrder, deleteLearningEventParticipant, fetchLearningEvent} from "store/userGroup";
import {Box, Grid, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip} from "@mui/material";
import {TextFormFieldPlain} from "components/form/TextFormField";
import {CheckboxPlain, SwitchPlain} from "components/form/CheckboxFormField";
import Button from "@mui/material/Button";
import {AddOutlined, CheckCircle, CheckCircleOutlined, CopyAllOutlined, EditOutlined, InfoRounded} from "@mui/icons-material";
import {LearningEvent, UserGroup} from "pages/UserGroupsPage";
import {BulkSendInvitationResponseInvitedUsersInner, ContentLearningEventShowResponseOrdersInner} from "generated-api";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles/createTheme";
import {DataGridItemAction, DataGridItemActions, isSearchWithinSubject} from "components/DataGrid";
import {addMessage} from "store/localApp";
import {isApiResultError} from "../../helpers/api";
import {getNgService, sortByFullname} from "utils/utils";
import {useModal} from "services/modal";
import {dateToGui} from "../../helpers/date";
import {EditOrderNoteDialog} from "components/userGroup/EditOrderNoteDialog";
import {EditParticipantNoteDialog} from "components/userGroup/EditParticipantNoteDialog";

interface Order extends ContentLearningEventShowResponseOrdersInner {

}

interface Participant extends BulkSendInvitationResponseInvitedUsersInner {

}

const orderStyle: { [key in 'internal' | 'confirmed' | 'unconfirmed' | 'canceled']: { [key in 'header']: SxProps<Theme> } } = {
    internal: {
        header: {background: '#EEEEEE', 'th': {color: 'var(--color-black-text)'}}
    },
    unconfirmed: {
        header: {background: 'var(--color-warning-back)', 'th': {color: 'var(--color-black-text)'}}
    },
    confirmed: {
        header: {background: 'var(--color-primary-default15)', 'th': {color: 'var(--color-black-text)'}}
    },
    canceled: {
        header: {background: '#EEEEEE', 'td': {textDecoration: 'line-through'}}
    },
}

const noteSx: SxProps = {
    maxWidth: '200px',
    textAlign: 'left',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '80%',
    lineHeight: 1,
    textTransform: 'none !important',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    '& *': {
        margin: '0'
    }
}

const settledSuccessStyle = {
    fontSize: '140%',
    verticalAlign: 'middle',
    color: 'var(--color-success) !important',
    marginRight: '5px'
}

const settledWarningStyle = {
    fontSize: '140%',
    verticalAlign: 'middle',
    color: 'var(--color-warning)  !important'
}

const NoteCell = ({note, handleEdit, isCreateDisabled}: { note?: string, handleEdit: () => void, isCreateDisabled?: boolean }) => {
    const t = useAppTranslation();

    return <TableCell>{note ? <Tooltip title={<div>
            <strong>{t('userGroup.registration.note')}</strong>
            <div dangerouslySetInnerHTML={{__html: note}}></div>
        </div>}><Button color={'inherit'} variant={'text'} onClick={handleEdit}>
            <Box sx={noteSx} dangerouslySetInnerHTML={{__html: note}}/>
        </Button></Tooltip>
        : (isCreateDisabled ? null : <Button color={'inherit'} variant={'text'} onClick={handleEdit}><EditOutlined/></Button>)
    }</TableCell>
}

const UserGroupOrder = (
    {
        order, search,
        onAddParticipant, onRemoveParticipant, onShowParticipant,/* onEditParticipantNote, */onEditOrderNote, onConfirmOrder,
        onOrderDetail, onOrderHistory, onCancelOrder,
        hasMassActions
    }: {
        order: Order,
        search?: string,
        onAddParticipant: (event?: any) => void,
        onRemoveParticipant: (participant: Participant) => void,
        onShowParticipant: (participant: Participant) => void,
        onEditParticipantNote: (participant: Participant) => void,
        onEditOrderNote: () => void,
        onConfirmOrder: () => void,
        onOrderDetail: () => void,
        onOrderHistory: () => void,
        onCancelOrder: () => void,
        hasMassActions: boolean,
    }) => {
    const t = useAppTranslation();
    const dispatch = useAppDispatch();

    const handleCopyLink = useCallback(async () => {
        await navigator.clipboard.writeText(order.invitation_link);
        dispatch(addMessage({
            severity: 'success',
            title: t('userGroup.registration.link.copied')
        }));
    }, [order.invitation_link, dispatch, t]);

    const orderActions: DataGridItemAction<Order>[] = useMemo(() => {
        if (order.status === 'cancelled') {
            return [];
        }
        return order.internal ? [
            {title: 'userGroup.registration.addUser', callback: onAddParticipant}
        ] : ([
            true ? null : {title: 'userGroup.registration.orderDetail', callback: onOrderDetail},
            order.settled_at ? null : {title: 'userGroup.registration.confirm.button', callback: onConfirmOrder},
            {title: 'userGroup.registration.addParticipant', callback: onAddParticipant},
            true ? null : {title: 'userGroup.registration.orderHistory', callback: onOrderHistory},
            {title: 'userGroup.registration.cancel.label', callback: onCancelOrder, color: 'error'}
        ].filter(a => !!a) as DataGridItemAction<Order>[]) || undefined
    }, [order, onAddParticipant, onConfirmOrder, onOrderDetail, onOrderHistory, onCancelOrder]);

    const isInternal = order.internal;

    return <TableBody sx={{'&:has(tr:first-of-type:last-of-type)': {td: {borderBottom: '10px solid white'}}}}>
        <TableRow sx={orderStyle[isInternal ? 'internal' : (order.status === 'cancelled' ? 'canceled' : (order.settled_at ? 'confirmed' : 'unconfirmed'))].header}>
            <TableCell>
                {hasMassActions && <CheckboxPlain name={'toggleRows'} disabled={true}/>}
            </TableCell>
            <TableCell>{isInternal ? t('userGroup.registration.internalUsers') : <>{order.company_space?.name} <Tooltip title={<div>
                <strong>{order.company_space?.name}</strong>
                <div>
                    {order.order_creator?.full_name}<br/>
                    {order.order_creator?.email}
                </div>
            </div>}><InfoRounded sx={{fontSize: '100%'}}/></Tooltip></>}</TableCell>
            <TableCell>{order.company_email}</TableCell>
            <TableCell>
                <Tooltip title={<div>
                    <strong>{t('userGroup.registration.link.label')}</strong>
                    <div dangerouslySetInnerHTML={{__html: t('userGroup.registration.link.hint')}}/>
                </div>}>
                    <Button color={'inherit'} variant={'text'} onClick={handleCopyLink}>
                        <CopyAllOutlined/>
                    </Button>
                </Tooltip>
            </TableCell>
            <NoteCell note={order.note} isCreateDisabled={order.internal} handleEdit={onEditOrderNote}/>
            <TableCell>{order.order_number}</TableCell>
            <TableCell>{dateToGui(order.submitted_at)}</TableCell>
            {order.internal || order.status === 'cancelled' ? <TableCell></TableCell> : <TableCell>
                {!!order.settled_at
                    ? <CheckCircle sx={settledSuccessStyle}/>
                    : <CheckCircleOutlined sx={settledWarningStyle}/>}
                {!!order.settled_at
                    ? dateToGui(order.settled_at)
                    : <Button variant={'text'} color={'inherit'} onClick={onConfirmOrder}
                        className={'tw-normal-case tw-underline'}>{t('userGroup.registration.confirm.label')}</Button>}
            </TableCell>}
            <TableCell>{!!order.license_count && (order.participants_count + ' / ' + order.license_count)}</TableCell>
            <TableCell>{order.license_count !== undefined && order.participants_count !== undefined && true && (order.license_count - order.participants_count)}</TableCell>
            <DataGridItemActions item={order} isActionMenu actions={orderActions}/>
        </TableRow>
        {order.participants
            ?.filter(p => isSearchWithinSubject(search, p.full_name))
            ?.sort(sortByFullname)
            ?.map((participant, i) => <TableRow key={i}>
                <TableCell>
                    {hasMassActions && <CheckboxPlain name={'toggleRow' + i}/>}
                    <img alt={participant.full_name} style={{width: '32px', borderRadius: '50%', verticalAlign: 'middle', marginLeft: '-8px'}}
                        src={participant.small_picture_url || participant.picture_url}/>
                </TableCell>
                <TableCell>{participant.full_name}</TableCell>
                <TableCell>{participant.email}</TableCell>
                <TableCell></TableCell>
                <TableCell>
                    {/*<NoteCell note={participant.note} handleEdit={() => onEditParticipantNote(participant)}/>*/}
                </TableCell>
                <TableCell colSpan={5}></TableCell>
                <DataGridItemActions item={participant} isActionMenu actions={[
                    {title: 'userGroup.registration.removeUser.action', callback: onRemoveParticipant},
                    {title: 'userGroup.registration.showUser', callback: onShowParticipant}
                ]}/>
            </TableRow>)}
        {order.internal && <TableRow>
            <TableCell colSpan={11}>
                <Tooltip placement={'right-end'} title={<div>
                    <strong>{'Přidání uživatele do plánu'}</strong>
                    <div dangerouslySetInnerHTML={{__html: 'Přidejte interní uživatele z organizace. Stačí zadat e-mail nebo jméno.'}}/>
                </div>}>
                    <Button color={'dark' as any} variant={'text'}
                        onClick={onAddParticipant}
                        className={'tw-w-auto tw-normal-case'}>
                        <AddOutlined/>
                        &nbsp;&nbsp;&nbsp;&nbsp;{t('userGroup.registration.addUser')}
                    </Button>
                </Tooltip>
            </TableCell>
        </TableRow>}
    </TableBody>
}

export const UserGroupRegisteredTab = ({userGroup}: { userGroup: UserGroup }) => {

    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const modal = useModal();

    const [search, setSearch] = useState('');
    const [showEmpty, setShowEmpty] = useState(false);
    const [showAvailable, setShowAvailable] = useState(false);
    const [showUnconfirmed, setShowUnconfirmed] = useState(false);
    const [showCanceled, setShowCanceled] = useState(false);
    const [learningEvent, setLearningEvent] = useState<LearningEvent>();
    const [order, setOrder] = useState<Order>();
    const [participant, setParticipant] = useState<Participant>();
    const [isLoading, setIsLoading] = useState(false);

    const fetchEvent = useCallback(async () => {
        if (!userGroup.learning_event?.id) {
            return;
        }
        setIsLoading(true);
        try {
            const res = await dispatch(fetchLearningEvent({id: String(userGroup.learning_event.id)}));
            if (!isApiResultError(res)) {
                setLearningEvent(res.payload as LearningEvent);
            }
        } finally {
            setIsLoading(false);
        }
    }, [userGroup, dispatch]);

    const handleAddParticipant = useCallback(async (event?: any) => {
        try {
            // addUserToGroupDialog.showDialog(userGroup, event, isAdminDashboard)
            await getNgService('addUserToGroupDialog').showDialog(userGroup, event, false);
            await fetchEvent();
        } catch (_) {
        }
    }, [userGroup, fetchEvent]);

    const handleShowParticipant = useCallback(async (participant: Participant) => {
        try {
            // userDetailDialog.showUserDetail(user, event, ctrl.users.removeItem.bind(ctrl.users, index));
            await getNgService('userDetailDialog').showUserDetail(participant);
            await fetchEvent();
        } catch (_) {
        }
    }, [fetchEvent]);

    const handleRemoveParticipant = useCallback(async (participant: Participant) => {
        if (!learningEvent?.id) {
            return;
        }
        const result = await modal.confirm({
            title: t('userGroup.registration.removeUser.title'),
            message: t('userGroup.registration.removeUser.body', participant),
            confirmText: t('userGroup.registration.removeUser.button')
        });
        if (result !== 'CONFIRM') {
            return;
        }
        await dispatch(deleteLearningEventParticipant({learningEventId: learningEvent.id, id: '' + participant.user_in_company_space_id}));
        dispatch(addMessage({
            severity: 'success',
            title: t('userGroup.registration.removeUser.success')
        }));
        await fetchEvent();
    }, [learningEvent?.id, fetchEvent, t, modal, dispatch]);

    const handleEditOrderNote = useCallback((order: Order) => {
        setOrder(order);
    }, []);

    const handleEditParticipantNote = useCallback((participant: Participant) => {
        setParticipant(participant);
    }, []);

    const handleConfirmOrder = useCallback(async (order: Order) => {
        if (!learningEvent?.id) {
            return;
        }
        const result = await modal.confirm({
            title: t('userGroup.registration.confirm.label'),
            message: t('userGroup.registration.confirm.message', {order: order.company_space?.name}),
            confirmText: t('userGroup.registration.confirm.button')
        });
        if (result !== 'CONFIRM') {
            return;
        }
        await dispatch(confirmLearningEventOrder({
            id: String(order.id),
            learningEventId: learningEvent.id,
            body: {}
        }));
        dispatch(addMessage({
            severity: 'success',
            title: t('userGroup.registration.confirm.success')
        }));
        await fetchEvent();
    }, [learningEvent?.id, fetchEvent, modal, t, dispatch]);

    const handleCancelOrder = useCallback(async (order: Order) => {
        if (!learningEvent?.id) {
            return;
        }
        const result = await modal.confirm({
            title: t('userGroup.registration.cancel.label'),
            message: t('userGroup.registration.cancel.message', {order: order.company_space?.name}),
            confirmText: t('userGroup.registration.cancel.button'),
            cancelText: t('userGroup.registration.cancel.cancel'),
        });
        if (result !== 'CONFIRM') {
            return;
        }
        await dispatch(cancelLearningEventOrder({
            id: String(order.id),
            learningEventId: learningEvent.id,
            body: {}
        }));
        dispatch(addMessage({
            severity: 'success',
            title: t('userGroup.registration.cancel.success')
        }));
        await fetchEvent();
    }, [learningEvent?.id, fetchEvent, modal, t, dispatch]);

    const handleOrderDetail = useCallback(async (order: Order) => {
        if (!learningEvent?.id) {
            return;
        }
        await modal.info({
            title: t('userGroup.registration.detail.label'),
            message: t('userGroup.registration.detail.message', {order: order.company_space?.name}),
        });

    }, [learningEvent?.id, modal, t]);

    const handleOrderHistory = useCallback(async (order: Order) => {
        if (!learningEvent?.id) {
            return;
        }
        await modal.info({
            title: t('userGroup.registration.history.label'),
            message: t('userGroup.registration.history.message', {order: order.company_space?.name}),
        });

    }, [learningEvent?.id, modal, t]);

    const filteredOrders = useMemo(() => {
        const sorted = learningEvent?.orders
            ?.filter(o => !showEmpty || (!o.participants_count && !o.internal))
            ?.filter(o => !showAvailable || ((o.license_count || 0) > (o.participants_count || 0)) || o.internal)
            ?.filter(o => !showUnconfirmed || (!o.settled_at && !o.internal))
            ?.filter(o => showCanceled || o.status !== 'cancelled')
            ?.sort((a, b) => {
                if (a.internal !== b.internal) {
                    return a.internal ? 1 : -1;
                }
                return ((a.company_space?.name || '') > (b.company_space?.name || '')) ? 1 : -1;
            });
        if (!search || !sorted) {
            return sorted;
        }
        // const matchedOrders = learningEvent.orders
        //     .filter(o => isSearchWithinSubject(search, o.internal ? t('userGroup.registration.internalUsers') : o.company_space?.name));

        return sorted.filter(o => o.participants?.find(p => isSearchWithinSubject(search, p.full_name)));
    }, [search, showCanceled, showEmpty, showAvailable, showUnconfirmed,
        learningEvent?.orders]);

    useEffect(() => {
        fetchEvent().then();
    }, [fetchEvent]);

    if (learningEvent === undefined) {
        return <LinearProgress/>;
    }

    const hasMassActions = false;

    const tableStyle: SxProps = {
        marginTop: '32px',
        'th.MuiTableCell-head, th, td': {
            padding: '4px 4px',
            border: 'none', '.MuiFormControlLabel-root': {
                margin: 0
            }
        },
        '.MuiTableHead-root': {
            'th': {
                color: '#8D8D8D',
                fontWeight: 'normal',
                border: 'none !important',
                borderBottom: '1px solid #8D8D8D !important'
            }
        },
        '.MuiTableCell-root:first-of-type:not(:last-of-type)': {
            width: '45px',
            maxWidth: '45px',
            padding: '4px 16px'
        }
    }

    return <Grid container sx={{padding: '16px 24px', background: '#fff'}}>
        <Grid item xs={12}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                    <TextFormFieldPlain
                        name={'search'}
                        currentValue={search}
                        onChange={setSearch}
                        label={t('userGroup.filter.search.label')}
                        autoComplete={'off'}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={8} style={{textAlign: 'right'}}>
                    <SwitchPlain name='showCanceled' label={'Prázdné'}
                        labelPlacement={'start'}
                        currentValue={showEmpty}
                        compact
                        onChange={(value) => {
                            setShowEmpty(!showEmpty)
                        }}
                    />
                    <SwitchPlain name='showCanceled' label={'Volná místa'}
                        labelPlacement={'start'}
                        currentValue={showAvailable}
                        compact
                        onChange={(value) => {
                            setShowAvailable(!showAvailable)
                        }}
                    />
                    <SwitchPlain name='showCanceled' label={'Nepotvrzené'}
                        labelPlacement={'start'}
                        currentValue={showUnconfirmed}
                        compact
                        onChange={(value) => {
                            setShowUnconfirmed(!showUnconfirmed)
                        }}
                    />
                    <SwitchPlain name='showCanceled' label={'Stornované'}
                        labelPlacement={'start'}
                        currentValue={showCanceled}
                        compact
                        onChange={(value) => {
                            setShowCanceled(!showCanceled)
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} className={'tw-relative'}>
            {isLoading && <LinearProgress className={'tw-absolute tw-left-0 tw-right-0'}/>}
            <TableContainer sx={tableStyle}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {hasMassActions && <CheckboxPlain name={'toggleAll'}/>}
                            </TableCell>
                            <TableCell>{t('userGroup.registration.participant')}</TableCell>
                            <TableCell>{t('userGroup.registration.email')}</TableCell>
                            <TableCell>{t('userGroup.registration.invitation')}</TableCell>
                            <TableCell>{t('userGroup.registration.note')}</TableCell>
                            <TableCell>{t('userGroup.registration.order')}</TableCell>
                            <TableCell>{t('userGroup.registration.ordered')}</TableCell>
                            <TableCell>{t('userGroup.registration.confirmed')}</TableCell>
                            <TableCell>{t('userGroup.registration.userCount')}</TableCell>
                            <TableCell>{t('userGroup.registration.available')}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    {filteredOrders?.map((order, i) => <UserGroupOrder key={i}
                        order={order}
                        search={search}
                        onAddParticipant={handleAddParticipant}
                        onRemoveParticipant={handleRemoveParticipant}
                        onShowParticipant={handleShowParticipant}
                        onEditParticipantNote={handleEditParticipantNote}
                        onEditOrderNote={() => handleEditOrderNote(order)}
                        onConfirmOrder={() => handleConfirmOrder(order)}
                        onOrderDetail={() => handleOrderDetail(order)}
                        onOrderHistory={() => handleOrderHistory(order)}
                        onCancelOrder={() => handleCancelOrder(order)}
                        hasMassActions={hasMassActions}
                    />)}
                    {!filteredOrders?.length && <TableBody><TableRow>
                        <TableCell></TableCell>
                        <TableCell colSpan={10} className={'tw-p-0'}>
                            <div className={'data-grid-zero-data'}>{t('common.emptySearch')}</div>
                        </TableCell>
                    </TableRow></TableBody>}
                </Table>
            </TableContainer>
        </Grid>
        {order && learningEvent?.id && <EditOrderNoteDialog order={order}
            learningEventId={learningEvent.id}
            onSuccess={fetchEvent}
            onClose={() => setOrder(undefined)}/>}
        {participant && learningEvent?.id && <EditParticipantNoteDialog participant={participant}
            learningEventId={learningEvent.id}
            onSuccess={fetchEvent}
            onClose={() => setParticipant(undefined)}/>}
    </Grid>;
}
